<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>群分享海报：</span>
        <span class="remark">分享创群海报设置，建议尺寸为宽度为375px，高度700px，大小必须小于等于2M</span>
      </div>
      <el-upload
        accept="image/jpg, image/png, image/jpeg, image/gif"
        class="avatar-uploader"
        :action="imgApi"
        :show-file-list="false"
        :before-upload="handleBeforeSuccess1"
        :on-success="handleAvatarSuccess1"
        list-type="picture-card"
        style="display: inline-block;marin-left:10px;"
      >
        <img v-if="ruleForm.GroupSharePoster" :src="imgUrl + ruleForm.GroupSharePoster" class="upload-avatar" />
        <i v-else class="el-icon-plus"></i>
      </el-upload>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>分享链接卡片图：</span>
        <span class="remark">分享到群的链接卡片图设置，建议尺寸宽度为250px，高度200px，大小必须小于等于2M</span>
      </div>
      <el-upload 
        class="avatar-uploader" 
        :action="imgApi"
        :show-file-list="false"
        :before-upload="handleBeforeSuccess2" 
        :on-success="handleAvatarSuccess2"
        list-type="picture-card"
        style="display: inline-block;marin-left:10px;"
        accept="image/jpg, image/png, image/jpeg, image/gif"
		 >
        <img v-if="ruleForm.GroupShareShareCardPic" :src="imgUrl + ruleForm.GroupShareShareCardPic" class="upload-avatar" />
        <i v-else class="el-icon-plus"></i>
      </el-upload>
    </el-card>
    
    <div class="footer">
      <el-button style="width:240px;" type="primary" @click="saveForm" :loading="saveLoading">保存</el-button>
    </div>
  </div>
</template>

<script>
import config from '@/config/index';
import {
  groupShareConfig,
  groupShareSet,
} from '@/api/goods'
export default {
  data () {
    return {
      imgUrl: config.IMG_BASE,
      imgApi: config.UPLOAD_IMG,
      loading: false,
      saveLoading: false,
      ruleForm: {
        GroupSharePoster: null,
        GroupSharePosterComplete : null,
        GroupShareShareCardPic : null,
        GroupShareShareCardPicComplete: null,
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      let result = await groupShareConfig()
      this.ruleForm = result.Result
      this.loading = false
    },
    async saveForm() {
      this.saveLoading = true
      try{
        let result = await groupShareSet({
          GroupSharePoster: this.ruleForm.GroupSharePoster,
          GroupShareShareCardPic: this.ruleForm.GroupShareShareCardPic
        })
        console.log("this.ruleForm",this.ruleForm)
        if(result.IsSuccess) {
          this.$message({
            showClose: true,
            message: '保存成功',
            type: 'success'
          })
        }
      }catch(e) {

      }finally {
        this.saveLoading = false
      }
    },
    handleBeforeSuccess1(file) {
      const isLt2M = file.size / 1024 /  1024 < 2;
      if (!isLt2M) {
        alert('上传图片大小不能超过2M!');
        return false;
      }
    },
    handleAvatarSuccess1(res) {
      this.ruleForm.GroupSharePoster = res[0];
    },
    handleBeforeSuccess2(file) {
      const isLt2M = file.size / 1024 /  1024 < 2;
      if (!isLt2M) {
        alert('上传图片大小不能超过2M!');
        return false;
      }
    },
    handleAvatarSuccess2(res) {
      this.ruleForm.GroupShareShareCardPic = res[0];
    },
}
}
</script>

<style lang='less' scoped>
.box-card {
  margin-bottom: 10px;
  .remark {
    color: #909399;
    font-size: 14px;
  }
  .upload-avatar {
		width: 145px;
		height: 145px;
		object-fit: contain;
	}
}

.footer {
  box-shadow: 13px 1px 6px #999;
  line-height: 80px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
}

</style>